import ajax from './ajax'
// 1. 定义基础路径
//const BASE_URL = 'http://127.0.0.1:3000/';//本地测试接口
const BASE_URL = 'https://www.limengfei.top/';   //线上接口

export const adminLogin = (data)=> ajax(BASE_URL+'admin/login',data,'POST'); //普通登录接口
export const adminUser = (data) => ajax(BASE_URL+'admin/getUserInfo',data,'POST');  //获取用户信息与分页
export const adminRemarkPower = (data) => ajax(BASE_URL+'admin/remarkPower',data,'POST');  //评论权限
export const adminRemarkNum = (data) => ajax(BASE_URL+'admin/remarkNum',data,'POST'); //编辑评论条数
export const adminUserDelete = (data) => ajax(BASE_URL+'admin/userDelete',data,'POST'); //用户管理的删除操作
export const adminUploadImage = (data) =>ajax(BASE_URL+'admin/uploadImage',data,'POST'); //文章的图片上传
export const adminArticleEdit = (data) =>ajax(BASE_URL+'admin/articleEdit',data,'POST'); //文章的编辑 
export const adminArticleEditPagination = (data) =>ajax(BASE_URL+'admin/articleEditPagination',data,'POST');//文章编辑的翻页
export const adminFriendLinkAdmin = (data)=>ajax(BASE_URL+'admin/friendLinkAdmin',data,'POST');//友链翻页与渲染
export const adminFrendLinkOperation = (data)=>ajax(BASE_URL+'admin/friendLinkOperation',data,'POST'); //友链的相关操作
export const adminFriendLinkDelete = (data)=>ajax(BASE_URL+'admin/friendDelete',data,'POST'); //友链的删除
export const adminFriendLinkUpdate = (data) =>ajax(BASE_URL+'admin/updateFriendLink',data,'POST'); //友链的更新
export const adminFriendLinkAdd = (data) =>ajax(BASE_URL+'admin/addFriendLink',data,'POST'); //手动添加友链
export const adminLoginOut = () =>ajax(BASE_URL+'admin/loginout');//退出登录
export const adminArticleDelete = (data)=>ajax(BASE_URL+'admin/articleDelete',data,'POST'); //文章的删除
export const adminArticleUpate = (data) =>ajax(BASE_URL+'admin/updateArticle',data,'POST'); //文章的更新
export const adminArticleGotop = (data) =>ajax(BASE_URL+'admin/hanldeArticleGoTop',data,'POST');  //文章的置顶
export const adminArticleRemarkPower = (data) =>ajax(BASE_URL+'admin/handleArticleRemarkPower',data,'POST'); //文章的评论权限
export const adminArticleCombat = (data) =>ajax(BASE_URL+'admin/handleArticleCombat',data,'POST'); //是否项目实战
export const adminMyMusicInitData = (data) =>ajax(BASE_URL+'admin/myMusicInitData',data);//音乐管理页面初始化
export const adminMyMusicDelete = (data) =>ajax(BASE_URL+'admin/myMusicDelete',data,'POST');  // 音乐删除
export const adminMyMusicAlter = (data) =>ajax(BASE_URL+'admin/myMusicAlter',data,'POST');// 音乐的修改
export const adminMyMusicAdd = (data) =>ajax(BASE_URL+'admin/addMusic',data,'POST');//添加音乐
export const adminMyAboutMe = (data)=>ajax(BASE_URL+'admin/saveMyinfoAdmin',data,'POST'); //保存或者更新客户端AboutMe页面
export const adminMyAboutMeinit = ()=>ajax(BASE_URL+'admin/initMyinfoAboutMe'); //初始化关于此站和我
export const adminMyTimestampInit = ()=>ajax(BASE_URL+'admin/initTimeStamp'); //时间线的初始化
export const adminMyAddTimestamp = (data)=>ajax(BASE_URL+'admin/addTimeStamp',data,'POST');//添加事件
export const adminMyAddTimestampDelete = (data)=>ajax(BASE_URL+'admin/deleteTimeStamp',data,'POST');//删除事件
export const adminMyAddTimestampEdit = (data)=>ajax(BASE_URL+'admin/editTimestamp',data,'POST');//编辑事件

//以下为客户端需要调用的接口
export const clientHome = (data) =>ajax(BASE_URL+'client/home',data);//GET 请求 主页初始化
export const clientHomeHeaderNavilst = ()=>ajax(BASE_URL+'client/headNavist');//顶部导航数据
export const clientHomeSiderInfo = () =>ajax(BASE_URL+'client/sidebarInfo'); //侧边栏资料
export const clientDetailArticle = (data)=>ajax(BASE_URL+'client/detailArticle',data); //文章详情
export const clientArticleQuery = (data)=>ajax(BASE_URL+'client/queryArticle',data);//文章的搜索
export const clientUserLogin = (data)=>ajax(BASE_URL+'client/getLoginInfo',data);//QQ登录
export const clientUserRemark = (data) =>ajax(BASE_URL+'client/articleRemark',data,'POST'); //用户评论
export const clientInitRemarkContent = (data)=>ajax(BASE_URL+'client/remarkCotent',data); //文章评论内容初始化
export const clientRemarkCallBack = (data) =>ajax(BASE_URL+'client/remarkCallback',data,'POST'); //评论的回复功能
export const clientTagsCategory = () =>ajax(BASE_URL+'client/getTagscategory'); //分类标签初始化
export const clientCombat = ()=>ajax(BASE_URL+'client/getCombat');//实战模块初始化内容
export const clientGetMyMusic = () =>ajax(BASE_URL+'client/getMyMusic'); //初始化音乐
export const clientGetFriendLink = ()=>ajax(BASE_URL+'client/getFriendLink');//初始化友情链接
export const clientGetTip = ()=>ajax(BASE_URL+'client/TipData'); //加载提示
export const clientGetCombatSearch = (data) =>ajax(BASE_URL+'client/getCombatSearch',data);  //实战部分的搜索
export const clientThumb = (data) => ajax(BASE_URL+'client/thumb',data,'POST');  // 评论点赞
export const clientSubmitFriendLink = (data) =>ajax(BASE_URL+'client/applyFriendLink',data,'POST'); //提交友链申请的信息
export const clientSubmitFriendLinkRemark = (data) =>ajax(BASE_URL+'client/friend_link_remark',data,'POST'); //友链的评论
export const clientFriendLinkRemarkInit = ()=>ajax(BASE_URL+'client/initFriendLinkRemark'); //友链区域评论的刷新或者初始化
export const clientFriendLinkThumb = (data) =>ajax(BASE_URL+'client/friend_thumb',data,'POST'); //友情链接的点赞
export const clientTimestamp = (data)=>ajax(BASE_URL+'client/timestamp',data,'POST'); //时间线的加载
export const clientAboutMeMyinfo = ()=>ajax(BASE_URL+'client/myAboutMeInfo');  //获取关于我的个人信息
export const clientShareImg = (data)=>ajax(BASE_URL+'client/uploadShareImg',data,'POST'); //分享区 的图片上传
export const clientShareInit = ()=>ajax(BASE_URL+'client/initShare');     //分享页面的初始化
export const clientShareShowMore = ()=>ajax(BASE_URL+'client/showMore'); //加载更多
export const clientShareThumbs = (data)=>ajax(BASE_URL+'client/thumbShare',data,'POST'); // 分享区 的点赞